import {Injectable, OnDestroy} from '@angular/core';
import {User, UserModel} from '../models/user';
import {RequesterService} from './requester.service';
import {BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import {first,map} from 'rxjs/operators';
import { StreamClientService } from './stream-client.service';
import { IdleUserDetectorService } from './idle-user-detector.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  public authState: Subject<boolean> = new Subject<boolean>();
  localStorageTokenFieldName = 'bearerToken';
  localStorageTokenExpiryFieldName = 'bearerTokenExpiry';
  localStorageUserFieldName = 'userDetails';
  idleSubscriptionEstablished = false;

  bearerToken = null;
  bearerTokenExpiry = null;
  userDetails: User | null = null;
  data$: Promise<any>;

  public vendorSubscription: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private requesterService: RequesterService,
    public streamClient: StreamClientService,
    private idleUserDetectorService: IdleUserDetectorService,
    private router : Router

  ) {
    this.authState.subscribe((state) => {
      if (state) {
        console.log('AUTHENTICATED------> NOW CHECK SUBSCRIPTION');
        this.getMySubscription().then((data) => {
          console.log('SUBSCRIPTION DATA>>>>', data.data);
          this.vendorSubscription.next(data.data);
        });
      } else {
        console.log('NOT AUTHENTICATED------> CLEAN SUBSCRIPTION');
        this.vendorSubscription.next(null);
      }
    } );

    this.getDataFromLocalStorage();
    // console.log(this.bearerToken, this.bearerTokenExpiry, this.userDetails)
    if (AuthService.masterIsLoggedIn) {
      this.streamClient.connect();
      this.authState.next(true);
    } else {
      this.authState.next(false);
    }
  }

  getMySubscription(): Promise<any> {
    return this.requesterService.request('get', 'payments/stripe/payment-status').toPromise();
  }
  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }

  public static get masterIsLoggedIn(): boolean {
    let bearerToken = null;
    let bearerTokenExpiry = null;
    let userDetails: User | null = null;
    if (localStorage.getItem('bearerToken')) {
      bearerToken = localStorage.getItem('bearerToken');
      if (localStorage.getItem('bearerTokenExpiry')) {
        bearerTokenExpiry = localStorage.getItem('bearerTokenExpiry');
      }
    }
    if (localStorage.getItem('userDetails')) {
      userDetails = new User(JSON.parse(localStorage.getItem('userDetails')));
    }
    // console.log(bearerToken, bearerTokenExpiry > (new Date()).getTime(),  !!userDetails);
    if (!(bearerToken && bearerTokenExpiry > (new Date()).getTime() && !!userDetails)) {
      AuthService.clearDataFromLocalStorage();
    }
    const app = localStorage.getItem('app');
    if (app !== 'vendor' && app !== 'workspace') {
      AuthService.clearDataFromLocalStorage();
      return false;
    }
    return !!(bearerToken && bearerTokenExpiry > (new Date()).getTime() && !!userDetails);
  }

  public get isLoggedIn(): boolean {
    return !!(this.bearerToken && this.bearerTokenExpiry > (new Date()).getTime() && !!this.userDetails);
  }

  public static setSection(name: string, value: any) {
    localStorage.setItem(name, JSON.stringify(value));
  }

  public static getSection(name: string) {
    return JSON.parse(localStorage.getItem(name));
  }

  public static updateUserDetails(key: string, value: any) {
    const userDetails = new User(JSON.parse(localStorage.getItem('userDetails')));
    userDetails[key] = value;
    localStorage.setItem('userDetails', JSON.stringify(new User(userDetails)));
  }

  public static getUserDetails(): User | null {
    if (localStorage.getItem('userDetails')) {
      return new User(JSON.parse(localStorage.getItem('userDetails')));
    }
    return null;
  }

  /* For  payment is applicable or not for user */
  public static isPaymentApplicable(): User | null {
    if (JSON.parse(localStorage.getItem('userDetails')).paymentEnabled) {
      return JSON.parse(localStorage.getItem('userDetails')).paymentEnabled;
    }
    return false;
  }

  public static isCompanyAdmin(): boolean {
    if (localStorage.getItem('admin')) {
      return JSON.parse(localStorage.getItem('admin'));
    }
    return false;
  }

  public static isExternalUser(): boolean {
    const user: any = new User(JSON.parse(localStorage.getItem('userDetails')));
    return user.role === 'external';
  }

  public static clearDataFromLocalStorage() {
    const localStorageTokenFieldName = 'bearerToken';
    const localStorageTokenExpiryFieldName = 'bearerTokenExpiry';
    const localStorageUserFieldName = 'userDetails';
    localStorage.removeItem(localStorageTokenFieldName);
    localStorage.removeItem(localStorageTokenExpiryFieldName);
    localStorage.removeItem(localStorageUserFieldName);
    localStorage.removeItem('app');
    localStorage.clear();
  }

  getDataFromLocalStorage() {
    if (localStorage.getItem(this.localStorageTokenFieldName)) {
      this.bearerToken = localStorage.getItem(this.localStorageTokenFieldName);
      if (localStorage.getItem(this.localStorageTokenExpiryFieldName)) {
        this.bearerTokenExpiry = localStorage.getItem(this.localStorageTokenExpiryFieldName);
      }
    }
    if (localStorage.getItem(this.localStorageUserFieldName)) {
      this.userDetails = new User(JSON.parse(localStorage.getItem(this.localStorageUserFieldName)));
    }
  }

  setDataToLocalStorage(userDetails: any, bearerToken: string, bearerTokenExpiry: string) {
    /* -------insert a flag for payment free  users  in userDetails for demo-------*/
    // userDetails.isPaymentFree = false;
    /* ----------------- */
    localStorage.setItem(this.localStorageTokenFieldName, bearerToken);
    localStorage.setItem('admin', String(userDetails.admin));
    localStorage.setItem(this.localStorageTokenExpiryFieldName, (new Date(bearerTokenExpiry)).getTime().toString());
    this.setUserDataInLocalStorage(userDetails);
    if(userDetails.role === 'external') {
      localStorage.setItem('app', 'workspace');
    } else {
      localStorage.setItem('app', 'vendor');
    }
  }

  // For Workspace
  setDataToLocalStorageForWorkspace(userDetails: any, bearerToken: string, bearerTokenExpiry: string) {
    localStorage.setItem(this.localStorageTokenFieldName, bearerToken);
    localStorage.setItem('accessType', 'workspace');
    localStorage.setItem(this.localStorageTokenExpiryFieldName, (new Date(bearerTokenExpiry)).getTime().toString());
    this.setUserDataInLocalStorage(userDetails);
    localStorage.setItem('app', 'workspace');
  }

  public setUserDataInLocalStorage(userDetails: User) {
    localStorage.setItem(this.localStorageUserFieldName, JSON.stringify(new User(userDetails)));
  }

  public login(email: string, password: string): Observable<any> {
    localStorage.clear();
    return this.requesterService.request('post', 'auth/login', {email, password}, false)
      .pipe(
        map((apiResponse: any) => {
          console.log(apiResponse);
          if (apiResponse.data && apiResponse.data.user && (apiResponse.data.user.role !== 'vendor' && apiResponse.data.user.role !== 'external')) {
            let msg = 'You are not allowed to login as a vendor as you are already registered with us as a ';
            if( apiResponse.data.user.role === 'owner' ) {
              msg += 'Buyer with the company ' + apiResponse.data.user.companyName;
            } else if( apiResponse.data.user.role === 'admin' ) {
              msg += 'Admin';
            }

            throw new Error(msg);
          }
          if (apiResponse.data) {
            this.setDataToLocalStorage(apiResponse.data.user, apiResponse.data.token, apiResponse.data.expireAt);
            this.authState.next(true);
            this.streamClient.connect();
          }
          return apiResponse;
        })
      );
  }

  public workspaceLogin(email: string, pin: string): Observable<any> {
    localStorage.clear();
    return this.requesterService.request('post', 'workspace/external-login', {email, pin}, false)
      .pipe(
        map((apiResponse: any) => {
          if (apiResponse.data) {
            this.setDataToLocalStorageForWorkspace(apiResponse.data.userDetails, apiResponse.data.token, apiResponse.data.expiry);
            this.authState.next(true);
          }
          return apiResponse;
        })
      );
  }


  public verifyRegistration(token: string): Observable<any> {
    localStorage.clear();
    return this.requesterService.request('post', 'auth/verify-email', {token}, false)
      .pipe(
        map((apiResponse: any) => {
          if (apiResponse.data) {
            this.setDataToLocalStorage(apiResponse.data.user, apiResponse.data.token, apiResponse.data.expireAt);
            this.authState.next(true);
            this.streamClient.connect();
          }
          return apiResponse;
        })
      );
  }


  public initialTokenLogin(token: string): Observable<any> {
    localStorage.clear();
    return this.requesterService.request('post', 'auth/one-time-token-login', {token}, false)
      .pipe(
        map((apiResponse: any) => {
          if (apiResponse.data) {
            this.setDataToLocalStorage(apiResponse.data.userDetails, apiResponse.data.token, apiResponse.data.expiry);
            this.authState.next(true);
          }
          return apiResponse;
        })
      );
  }

  public logout(): Promise<any> {
   return this.data$ =  new Promise<any>((resolve, reject) => {
      this.requesterService.request('get', 'auth/logout').subscribe(loggedOut => {
        AuthService.clearDataFromLocalStorage();
        this.authState.next(false);
        resolve(true);
      }, error => {
        AuthService.clearDataFromLocalStorage();
        this.authState.next(false);
        resolve(true);
      });
    });
  }

  public startIdleTimer() {
    if(!this.idleSubscriptionEstablished) {
      this.idleUserDetectorService.idleTimeOut
      .pipe(
        first(isIdle => isIdle === true)
      )
      .subscribe(async (isIdle) => {
        if (isIdle) {
          await this.logout();
          this.router.navigateByUrl("/auth/login");
        }
      });
      this.idleSubscriptionEstablished = true;
    }

  }

}
